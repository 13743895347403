import { render, staticRenderFns } from "./featured-cars-popular.vue?vue&type=template&id=2fe5fadf&"
import script from "./featured-cars-popular.vue?vue&type=script&lang=js&"
export * from "./featured-cars-popular.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FeaturedCarPopularImg: require('/home/d/didisher2/quant-autoplace.ru/www/components/featured/featured-car-popular-img.vue').default})
